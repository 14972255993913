<template>
	<div class="all">
		<div class="title">
			除治总量
		</div>
		<div class="content">
			<div class="left">
				<div style="font-size: 14px;margin-top:20px;">
					已除治疫木总数(棵)
				</div>
				<div style="color: #6ac2fd;font-size: 28px;margin-top:1rem;">
					{{sum}}
				</div>
			</div>
		<!-- <div class="right">
				<div class="left1">
					<radial_indicator 
					v-if="sum1"
					    :percentNum="sum1"
					    speed="3"
					    size="60"
					    color="#0094ff"
					    @animationFinished="animationFinished"
					    backgroundColor="#fff"
					    content=""
					    icon="mail"
					/>
					<div style="margin-left: 10px;">
						<p>已除治疫木</p>
						<p>{{sum}}</p>
					</div>
				</div>
				<div class="right1">
					<radial_indicator
					v-if="weichuzhi1"
					    :percentNum="weichuzhi1"
					    speed="3"
					    size="60"
					    color="#f60019"
					    @animationFinished="animationFinished"
					    backgroundColor="#fff"
					    content=""
					    icon="mail"
					/>
					<div style="margin-left: 10px;">
						<p>未除治疫木</p>
						<p>{{weichuzhi}}</p>
					</div>
				</div>
			</div> -->
		</div>
		
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import radial_indicator from './radial-indicator.vue'
	export default{
		data() {
			return{
				sum:null,
			  sum1:null,
				all:null,
				weichuzhi:null,
				weichuzhi1:null
			}
		},
		components:{
			radial_indicator
		},
		mounted(){
			this.handleAll()
		},
		methods:{
      // 除治数量状态
			handleAll(projectIdList){
				this.$axios(this.api+'/elimination/sum',{params:{
					projectIdList
				}}).then(res=>{
					this.sum=res.data.sum
					if(projectIdList){
						this.weichuzhi=Math.floor(Math.random() *100) + 300
					}else{
						this.weichuzhi=Math.floor(Math.random() *100) + 600
					}
					this.all=this.sum+this.weichuzhi
					this.sum1=Number((this.sum / this.all*100).toFixed(1))
					this.weichuzhi1=Number((100-this.sum1).toFixed(1))
				})
			},
      animationFinished(e) {
      // console.log(e)
    },
		}
	}
</script>

<style scoped>
	.all{
		padding: 20px;
		width: 100%;
		height: 100%;
	}
	.title{
	   width: 100%;
	   border-left:3px solid #00CCFF;
	   padding-left: 10px;
	   text-align: left;
	   color: #fff;
	}
	.content{
		/* display: flex; */
		color: #fff;
		margin-top: 5px;
		height: calc(100% - 20px);
		
	}
	.left{
		/* width: 120px; */
		text-align: center;
		/* display: flex; */
		flex-direction: column;
		align-items: center;
		justify-content: center;
		/* border-right:1px solid #797979 ; */
	}
	.right{
		width: calc(100% - 120px);
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.left1{
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	p{
		margin-bottom: 0;
	}
	.right1{
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>

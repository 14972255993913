<template>
	<div class="shanxing">
		<div class="shan_left">
			<div class="title">
				工作组进度
			</div>
		</div>
		<div class="shan_right">
			 <div id="main1" ref="echartContainer" style="width: 100%; height: 100%"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		data() {
			return {
				myChart: null,
				option: {
					grid: {
						x: 50,
						y: 40,
						x2: 20,
						y2: 30,
					},

					xAxis: {
						type: 'category',

						axisLine: {
							lineStyle: {
								type: 'solid',
								color: '#9d9c9a', //左边线的颜色
								width: '1' //坐标线的宽度
							}
						},
						axisLabel: {
								color: '#9d9c9a', //坐标值得具体的颜色
						},
						data: []
					},
					color: ['#1681e3'],
					yAxis: {
						type: 'value',
						name: '疫木数量（株）',
						axisLine: {
							lineStyle: {
								type: 'solid',
								color: '#fff', //左边线的颜色
								width: '2' //坐标线的宽度
							}
						},
						axisLabel: {
								color: '#9d9c9a', //坐标值得具体的颜色
						},
						nameTextStyle:{
							color:'#c6cdcf'
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#9d9c9a'],
								width: 1,
								type: 'dashed'
							}
						},
					},
					series: [{
						data: [],
						type: 'bar'
					}]
				},
			}
		},
		mounted() {
			this.handleList()
		},
		methods: {
      // 工作组进度
			handleList(projectIdList) {
				this.$axios(this.api + '/elimination/plan', {
					params: {
						projectIdList
					}
				}).then(res => {
					const data = res.data
					this.option.xAxis.data = []
					this.option.series[0].data = []
					data.forEach(res => {
						this.option.xAxis.data.push(res.groupName)
						this.option.series[0].data.push(res.groupPlan)
					})
					this.myChart = echarts.init(document.getElementById("main1"));
					this.$nextTick(_ => {
						this.myChart.setOption(this.option);
					})
					window.addEventListener('resize', this.resize())
				})
			},
			resize() {
				let that = this;
				let timer = null;
				return function() {
					if (timer) {
						clearTimeout(timer)
					}
					timer = setTimeout(() => {
						this.myChart = echarts.init(document.getElementById("main1"));
						this.myChart.resize();
					})
				}
			},
		}
	}
</script>

<style scoped>
	.shanxing {
		width: 100%;
		height: 100%;
		padding-top: 20px;
		/* display: flex; */
	}

	.shan_left {
		width: 100%;
		height: 20px;
		color: #fff;
		padding-left: 20px;
	}

	.title {
		width: 100%;
		border-left: 3px solid #00CCFF;
		padding-left: 10px;
		text-align: left;
	}

	.shan_right {
		width: 100%;
		height: calc(100% - 20px);
	}
</style>

<template>
	<div class="shanxing">
		<div class="shan_left">
			<div class="title">
				除治统计
			</div>
		</div>
		<div class="shan_right">
			<highcharts style="width: 100%;height:100%" :options="chartOptions" />
			<!-- <div id="container" style="min-width:400px;height:400px"></div> -->
			<!--  <div id="main2" ref="echartContainer" style="width: 100%; height: 100%"></div> -->
		</div>
	</div>
</template>

<script>
	// import * as echarts from 'echarts'
	// import HighCharts from 'highcharts'
		let data1 = []
		let data2 = []
    let lengths = 0
      function loopSet(array1, array2, array3) {
        let count = 1
        const [green, red] = this.series;
        setInterval(() => {
          if(data1.length > data2.length || data1.length == data2.length) {
            lengths = data1.length
          }
          if(data1.length < data2.length) {
            lengths = data2.length
          }
          let index = count % lengths
          green.addPoint({
            x: count + lengths,
            y: data1[index]
          }, false, count > 0)
          red.addPoint({
            x: count + lengths,
            y: data2[index]
          }, true, count > 0)
          count += 1
        }, 1000)
      }
	export default {
		data() {
			return {
         projectIdList: sessionStorage.getItem('projectId'), //根据项目id获取数据
				chartOptions: {
					 title: {
						text: '',
						align: 'left',
						style: {
							color: '#aaaaaa'
						}
					},
					xAxis: {
						title: {
							text: ''
						},
						labels: {
							style: {
								color: '#9d9c9a'
							}
						},
						tickPixelInterval: 150
					},
					legend: {
						align: "right", //程度标的目标地位
						verticalAlign: "top", //垂直标的目标地位
						itemStyle: {
							color: '#fff',
						}
					},
					yAxis: {
						title: {
							text: '疫木数量（株）',
							style: {
								color: '#fff'
							}
						},
						labels: {
							style: {
								color: '#9d9c9a'
							}
						},
						gridLineDashStyle: 'Dash',
						gridLineColor :"#9d9c9a"
					},
					colors: ['#3cacfd', '#2fc25b'],
					chart: {
						type: 'spline',
						backgroundColor: '#00000000',
						events: {
							load: loopSet
						},
					},
					series: [
            {
							data: [],
							name: '已除治',
						},
						{
							data: [],
							name: '未除治'
						}
					],
				}
			}
		},
		mounted() {
			var high = document.getElementsByClassName('highcharts-credits')[0];
			high.style.display='none';
      this.handleList()
		},
		methods: {
			handleList() {
				this.$axios(this.api + `/elimination/data?projectIdList=${this.projectIdList}`).then(res => {
          // 已除治
          res.data.isElimination.forEach(item => {
            data1.push(item.elimination)
          })
          // 未除治
          // res.data.notElimination.forEach(item => {
          //   data2.push(item.elimination)
          // })
					this.chartOptions.series[0].data=data1
					// this.chartOptions.series[1].data=data2
				})
			},
		}
	}
</script>

<style scoped>
	.shanxing {
		width: 100%;
		height: 100%;
		padding-top: 20px;
		/* display: flex; */
	}

	.shan_left {
		width: 100%;
		height: 20px;
		color: #fff;
		padding-left: 20px;
	}

	.title {
		width: 100%;
		border-left: 3px solid #00CCFF;
		padding-left: 10px;
		text-align: left;
	}

	.shan_right {
		width: 100%;
		height: calc(100% - 20px);
	}
</style>

<template>
	<div class="shanxing">
		<div class="shan_left">
			<div class="title">
				小班除治统计
			</div>
			<a-select style="margin-top:20px;width: 80px;margin-left: 20px;" default-value="1班" @change="handleChange">
			  <a-select-option :value="item.text" v-for="(item,index) in banList" :key="index">
			    {{item.text}}
			  </a-select-option>

			</a-select>
		</div>
		<div class="shan_right" >
			<dv-active-ring-chart :config="config" style="width:100%;height:100%" />
			<!--  <div id="main" ref="echartContainer" style="width: 100%; height: 100%"></div> -->
		</div>
	</div>
</template>

<script>
	// import * as echarts from 'echarts'
	export default{
		data() {
			return{
				config:{

				},
				banList:[
					{
						key:'1',
						text:'1班'
					},
					{
						key:'2',
						text:'2班'
					},
					{
						key:'3',
						text:'3班'
					},
					{
						key:'4',
						text:'4班'
					},
					{
						key:'5',
						text:'5班'
					},
				],
				treeClass:'1班',
				projectIdList:''
// },
			}
		},
		              mounted() {
						this.handleList()
		//            this.myChart = echarts.init(document.getElementById("main"));
		//         this.$nextTick(_=>{
		//             this.myChart.setOption(this.option);
		//         })
		//         window.addEventListener('resize',this.resize())
		    },
		methods:{
			handleChange(e){
				this.treeClass=e
				this.handleList()
			},
      // 小班除治
			 handleList(projectIdList){
				 if(projectIdList){
					this.projectIdList=projectIdList
				 }
			 				  this.$axios(this.api+'/elimination/group',{params:{
			 					  projectIdList:this.projectIdList,
								  treeClass:this.treeClass
			 				  }}).then(res=>{
			 					  const data=res.data
								  this.config={
									  radius: '75%',
					  activeRadius: '85%',
					  data: [
					    {
					      name: '已除治',
					      value:Number(data.isElimination)
					    },
					    {
					      name: '除治中',
					      value: Number(data.inElimination)
					    },
					    {
					      name: '未除治',
					      value: Number(data.notElimination)
					    },

					  ],
					  color:['#1984e6','#ff9900','#2cad59'],
					  digitalFlopStyle: {
					    fontSize: 20
					  }}
			 				  })
			 },
		}
	}
</script>

<style scoped>
	.shanxing{
		width: 100%;
		height:100%;
		padding: 20px;
		display: flex;
	}
	.shan_left{
		width: 30%;
		height: 100%;
		color: #fff;
	}
	.title{
	   width: 100%;
	   border-left:3px solid #00CCFF;
	   padding-left: 10px;
	   text-align: left;
	}
	.shan_right{
		width: 70%;
		height: 100%;
	}
</style>

<template>
	<div class="table">
		<div class="title">
			除治动态
		</div>
		<div class="table1">
			<a-spin :spinning="!spinning"></a-spin>
			<dv-scroll-board @click="moveClick" v-if="spinning && config.data.length>0" :config="config" style="width:100%;height:100%;" />
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				config:{
					header: ['编号','是否除治','林业小班','详情'],
					data: [],
					evenRowBGC:'#011c3e',
					oddRowBGC:'#1f325e',
					headerBGC:'#0099ff',
          align: ['center','center','center','center']
				},
				spinning:true,
			}
		},
		mounted(){
			this.handleList()
		},
		methods:{
      // 除治动态
			handleList(projectIdList){
				this.$axios(this.api+'/elimination/list',{params:{
					pageNum:1,
					pageSize:20,
					projectIdList
				}}).then(res=>{
					this.spinning=false
					this.$nextTick(()=>{
						this.handleDetail(res.data.list)
					})
				})
			},
			handleDetail(data){
				this.config.data=[]
				data.forEach(res=>{
					let array=[]
					array[0]=res.treeId
					array[1]='已除治'
					array[2]=res.treeClass
					array[3]='查看'
					this.config.data.push(array)
				})
				this.spinning=true
			},
      moveClick(rows) {
        this.$router.push({
					path:'/detail',
					query:{
						treeId:rows.row[0],
            uname: '查看'
					}
				})
      }
		}

	}
</script>

<style scoped="scoped">
	.table{
		padding: 20px;
		height: 100%;
	}
	.title{
		border-left: 3px solid #00CCFF;
		color: #fff;
	text-align: left;
	padding-left: 10px;
	}
	.table1{
		width: 100%;
		margin-top: 10px;
		height:calc(100% - 30px);
		position: relative;
	}
	.ant-spin{
		position: absolute;
		top:40%;
		left:50%;
	}
	.title1{
		width: 100%;
		display: flex;
		background-color: #0099ff;
	}
	.title1>div{
		flex: 1;
		height:40px;
		color: #fff;
		line-height: 40px;

	}
	.center{
		width: 100%;
		height: calc(100% - 50px);
		/* background-color: red; */
		overflow-y:auto;
	}
	.center .list{
		width: 100%;
		display: flex;

	}
	.center .list>div{
		flex: 1;
		height: 40px;
		line-height: 40px;
		color: #fff;
	}
	.center .active{
		background-color: #becad3;
		color: #0099ff !important;
	}
</style>

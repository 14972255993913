<template>
	<div class="index1">
		<!-- 地图点击疫木信息 -->
		<div class="map">
			<div id="container" style="width: 100%; height: 100%">
				<div
					class="i_message"
					ref="treePlate"
					:style="'display:' + display + ';'"
				>
					<div>编号：{{ forms.treeId }}</div>
					<div>经度：{{ forms.longitude }}</div>
					<div>纬度：{{ forms.latitude }}</div>
					<div>林业小班：{{ forms.treeClass }}</div>
					<div>所属项目：{{ forms.projectName }}</div>
					<div>创建时间：{{ forms.createTime }}</div>
					<div>状态：{{ forms.status }}</div>
				</div>
			</div>
		</div>
		<div class="top_left">
			<div class="tianqi">
				<div class="title">
					请选择项目：
					<a-select
						allowClear
						style="width: 120px; margin-right: 20px"
						v-model="projectName"
						@change="handleChange"
					>
						<a-select-option
							:value="item.projectId"
							v-for="(item, index) in projectList"
							:key="index"
						>
							{{ item.projectName }}
						</a-select-option>
					</a-select>
				</div>
				<div class="time">
					{{ this.Weather.date }} | {{ this.Weather.week }}
				</div>
				<div class="week">
					<div class="left">
						<div style="font-size: 36px">
							{{ this.Weather.temp }}°C
						</div>
						<div>天气:{{ this.Weather.weather }}</div>
					</div>
					<div class="right">
						<div>湿度:{{ this.Weather.humidity }}</div>
						<div>风力:{{ this.Weather.winddirect }}</div>
					</div>
				</div>
			</div>
			<!-- 小班除治统计 -->
			<div class="tongji">
				<Shanxing ref="shanxing" />
			</div>
		</div>
		<div class="top_right">
			<!-- 除治总量 -->
			<div class="zongliang">
				<All ref="all" />
			</div>
			<!-- 除治动态 -->
			<div class="dongtai">
				<Table ref="table" />
			</div>
		</div>
		<div class="content_foot">
			<!-- 工作组进度 -->
			<div class="foot_left">
				<Zhu ref="zhu" />
			</div>
			<!-- 除治统计 -->
			<div class="foot_right">
				<Zhexian />
			</div>
		</div>
	</div>
</template>

<script>
import Shanxing from './components/shanxing.vue' //小班除治统计
import Zhu from './components/zhu.vue' //工作组进度
import Zhexian from './components/zhexian.vue' //除治统计
import Table from './components/table.vue' //除治动态
import All from './components/all.vue' //除治总量
import gif from '../../assets/tree_red.png' //地图疫木展示图
import { wgs84_to_gcj02 } from '../../utils/wgs84_to_gcj02.js'

export default {
	name: 'index',
	data() {
		return {
			projectList: [], //项目列表
			Weather: {}, //天气信息
			map: null, //地图初始化
			zoom: 6, //地图初始展示级别
			center: [117.12277857421876, 36.64398657215667], //地图初始定位
			//地图点击疫木信息展示
			forms: {
				treeId: '',
				longitude: '',
				latitude: '',
				treeClass: '',
				projectName: '',
				createTime: '',
				status: '',
			},
			projectName: sessionStorage.getItem('projectName'), //项目名称
			projectId: sessionStorage.getItem('projectId'), //项目id
			display: 'none', // 树木信息显示/隐藏
		}
	},
	components: {
		Shanxing,
		Zhu,
		Zhexian,
		Table,
		All,
	},
	mounted() {
		// this.handleList()
		this.onLoad()
		this.getWeather()
		// this.handleProject()
		this.projectChange()
		this.handleChange(this.projectId)
	},
	methods: {
		// 选择的项目
		handleChange(e) {
			this.$refs.all.handleAll(e)
			this.$refs.table.handleList(e)
			this.$refs.zhu.handleList(e)
			this.$refs.shanxing.handleList(e)
			this.handleProject(e)
			this.onLoad()
		},
		// 项目列表
		projectChange() {
			let projectIdList = this.projectId
			this.$axios
				.get(this.api + `/project/list?projectIdList=${projectIdList}`)
				.then((res) => {
					this.projectList = res.data.list
				})
		},
		// 树标
		handleProject(e) {
			this.$axios
				.get(this.api + `/elimination/all?projectIdList=${e}`)
				.then((res) => {
					let array = []
					res.data.forEach((item) => {
						array.push(item)
					})
					this.setMaker(array)
				})
		},
		// 天气
		getWeather() {
			this.$axios
				.get(this.api + '/weather/info', { params: { city: '山东省' } })
				.then((res) => {
					const weather = res.data.result
					this.Weather = {
						date: weather.date,
						week: weather.week,
						weather: weather.weather,
						temp: weather.temp,
						humidity: weather.humidity,
						winddirect: weather.winddirect + weather.windpower,
					}
				})
		},
    // 疫木海量标记点
		setMaker(List) {
			var aaaa = []
			var cccc = {}
			var icon = {
				url: gif,
				size: new AMap.Size(32, 32), //设置icon的大小
				anchor: new AMap.Pixel(5, 5), // 图标显示位置偏移量，基准点为图标左上角
			}
			List.forEach((item) => {
				let adds = wgs84_to_gcj02(item.longitude-0, item.latitude-0)
			    let newlongitude = JSON.stringify(adds).split(',')[0].replace("[","")-0
			    let newlatitude = JSON.stringify(adds).split(',')[1].replace("]","")-0
				cccc = {
					item: item,
					lnglat: [newlongitude.toFixed(6), newlatitude.toFixed(6)],
				}
				aaaa.push(cccc)
			}) 
			var data = aaaa
			var massMarks = new AMap.MassMarks(null, {
				zIndex: 5, // 海量点图层叠加的顺序
				zooms: [3, 19], // 在指定地图缩放级别范围内展示海量点图层
				style: icon, // 设置样式对象
			})
			massMarks.on('click', this.getMarkerInfo) //设置标记点触发点击事件
			massMarks.setData(data)
			// 将海量点添加至地图实例
			massMarks.setMap(this.map)
		},
    // 点击疫木展示疫木信息
		getMarkerInfo(e) {
			this.display = 'block'
			this.center[0] = e.data.lnglat.lng
			this.center[1] = e.data.lnglat.lat
			if (this.zoom < 13) {
				this.zoom = this.zoom + 1
				this.map.setCenter(this.center) //设置中心点
				this.map.setZoom(this.zoom) //修改缩放比例
			} else {
				this.zoom = 13
				this.map.setCenter(this.center) //设置中心点
				this.map.setZoom(this.zoom) //修改缩放比例
			}
			let item = e.data.item
			let ustatus = ''
			if (item.status === '0') {
				ustatus = '未除治'
			} else if (item.status === '1') {
				ustatus = '除治中'
			} else if (item.status === '2') {
				ustatus = '已除治'
			}
			this.forms = {
				treeId: item.treeId,
				longitude: item.longitude,
				latitude: item.latitude,
				treeClass: item.treeClass,
				projectName: item.projectName,
				createTime: item.createTime,
				status: ustatus,
			}
			var content = this.$refs.treePlate
			var infoWindow = new AMap.InfoWindow({
				anchor: 'bottom-center',
				content: content,
			})
			infoWindow.open(this.map, [e.data.lnglat.lng, e.data.lnglat.lat])
		},
    // 地图初始化
		onLoad() {
			this.map = new AMap.Map('container', {
				center: this.center,
				// resizeEnable: true,//是否监控地图尺寸变化
				layers: [
					// 卫星
					new AMap.TileLayer.Satellite(),
					// 路网
					new AMap.TileLayer(),
				],
				zoom: this.zoom,
			})
		},
	},
}
</script>

<style scoped>
div /deep/ .ant-select-selection {
	color: #fff;
	background-color: rgba(37, 63, 91, 1);
}

div /deep/ .ant-select-arrow {
	color: rgb(249 240 240 / 91%);
}

.index1 {
	width: 100%;
	height: 100%;
	position: relative;
	border: none;
}

.map {
	width: 100%;
	height: 100%;
}

.content {
	position: absolute;
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	top: 20px;
	left: 20px;
}

.content_top {
	width: 100%;
	height: 62%;
	position: relative;
}

.top_left {
	width: 400px;
	height: 58%;
	position: absolute;
	top: 20px;
	left: 20px;
}

.tianqi {
	width: 100%;
	height: 50%;
	background-color: rgba(0, 36, 75, 0.8);
	border-radius: 15px;
	color: #fff;
	padding: 20px;
}

.tianqi .title {
	width: 100%;
	display: flex;
	height: 40px;
	align-items: center;
}

.tianqi .time {
	width: 100%;
	text-align: left;
	height: 30px;
	line-height: 30px;
}

.tianqi .week {
	width: 100%;
	height: calc(100% - 70px);
	display: flex;
}

.tianqi .week .left {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 18px;
}

.tianqi .week .right {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-size: 16px;
}

.tongji {
	width: 100%;
	height: 42%;
	margin-top: 20px;
	background-color: rgba(0, 36, 75, 0.8);
	border-radius: 15px;
}

.top_right {
	width: 428px;
	height: 58%;
	position: absolute;
	right: 20px;
	top: 20px;
}

.zongliang {
	width: 100%;
	height: 30%;
	background-color: rgba(0, 36, 75, 0.8);
	border-radius: 15px;
	float: right;
	margin-bottom: 20px;
}

.dongtai {
	width: 100%;
	height: 62%;
	background-color: rgba(0, 36, 75, 0.8);
	border-radius: 15px;
	overflow: hidden;
}

.content_foot {
	width: 100%;
	height: 34.5%;
	padding: 0 20px 20px 20px;
	display: flex;
	position: absolute;
	bottom: 0;
}

.foot_left {
	width: 400px;
	height: 100%;
	background-color: rgba(0, 36, 75, 0.8);
	border-radius: 15px;
}

.foot_right {
	width: calc(98% - 400px);
	height: 100%;
	margin-left: 2%;
	background-color: rgba(0, 36, 75, 0.8);
	border-radius: 15px;
}
/* 信息框 */
.i_message div {
	color: #000000;
	margin: 10px 8px 0 10px;
}
</style>
